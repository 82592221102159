import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w09KG07Laternen = () => (
  <Layout>
    <SEO title="w09KG07Laternen" />

    <h1 id="ueberschrift">Kreatives Gestalten</h1>
    <h2>Laternen basteln für Groß und Klein &emsp;  ©&nbsp;1998</h2>

<p className="note">!!!DIESER KURS WIRD AKTUELL AUSGESETZT!!!</p>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w09KG07Laternen&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Figuren, Bilder, Schattenspiele, etc.;<br />
        aus Tonpapier, Geschenkfolie, Servietten, etc.;<br />
        verziert mit Glitter, Perlen, Wolle, etc.
      </p>
      <p>
        Gemeinsam mit anderen können Sie Ihr kreatives Talent suchen und entdecken, indem Sie 
        unter Anleitung verschiedene Laternen aus bestimmten Grundmaterialien basteln.
        Genießen Sie die Zeit beim geselligen Experimentieren, Fluchen, Beneiden und Freuen.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w09KG07Laternen
